<template>
  <section class="contracted--companies">
    <h2 class="section-title">{{ mainTitle }}</h2>
    <p class="mt-5 all-quote">{{ titleDesc }}</p>
    <v-container>
      <!-- inline-css kaldırılacak. button kısmı orjinal resimler gelince tekrar düzenlenecek -->
      <v-row no-gutters class="justify-center">
        <v-col
          cols="4"
          sm="3"
          md="2"
          lg="1"
          class="contracted--companies--img mx-4 my-10"
          v-for="btn in sortedButtons"
          :key="btn.id"
        >
          <router-link :to="currentPath ? btn.path + currentPath : btn.path">
            <v-img class="object-fit" width="100%" height="auto" :src="btn.image"></v-img>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  props: {
    mainTitle: {
      type: String,
      required: false,
      default: 'Anlaşmalı Sigorta Şirketleri'
    },
    titleDesc: {
      type: String,
      required: false,
      default: 'Size en iyi fiyatı bulmak için şirketlerini karşılaştırıyoruz.'
    },
    buttons: {
      type: Array,
      require: true
    }
  },
  data () {
    return {
      currentPath: null
    }
  },
  mounted () {
    this.checkPath()
  },
  methods: {
    checkPath () {
      const ignoredPaths = ['/']
      const pageURL = this.$route.path
      const isValidate = ignoredPaths.indexOf(pageURL) > -1
      if (!isValidate) this.currentPath = pageURL
    }
  },
  computed: {
    sortedButtons () {
      if (this.buttons.length > 0) {
        const buttons = [...this.buttons]
        return buttons.sort((a, b) => {
          if (a.name < b.name) return -1
          if (a.name > b.name) return 1
          return 0
        })
      } else return []
    }
  }
}
</script>
<style scoped>
.object-fit {
  object-fit: contain;
}
</style>
