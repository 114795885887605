<template>
  <section class="get-quote">
    <v-container class="mx-auto">
      <h2 class="pt-10">{{ mainTitle }}</h2>
      <p class="all-quote mt-2">
        {{ titleDesc }}
      </p>
      <v-row class="mt-10">
        <v-col
          cols="12"
          sm="6"
          lg="3"
          v-for="card in cards"
          :key="card.id"
        >
          <v-card height="275" class="hover-card">
            <v-list-item>
              <v-list-item-content>
                <v-icon class="card-icon" x-large>
                  {{ card.icon }}
                </v-icon>
                <p class="card-title">
                  {{ card.title }}
                </p>
                <p class="card-description">
                  {{ card.desc }}
                </p>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p class="cards-desc">
            {{ slogan }}
          </p>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      mainTitle: 'Alokasko’dan fiyat teklifi almak çok kolay!',
      titleDesc:
        'İhtiyaçlarınızı ve seçeneklerinizi anlayın. En iyi şirketlerden sigorta fiyatlarını tek bir yerden alın.',
      slogan:
        ' Alokasko.com üzerinden ister fiyat teklifi talep edin ister online poliçe satın alın bilgileriniz daima bizimle güvende.',
      cards: [
        {
          id: 1,
          icon: 'fas fa-file-alt',
          title: 'Bilgilerinizi Girin, Fiyat Teklif Formunu Doldurun',
          desc:
            'En doğru sigorta fiyatlarına kolayca ulaşmak için bilgilerinizi eksiksiz girin.'
        },
        {
          id: 2,
          icon: 'fas fa-tasks',
          title: '20’den Fazla Sigorta Şirketini Karşılaştırın',
          desc: 'Zaman kazandıran fiyatlarını saniyeler içinde karşılaştırın.'
        },
        {
          id: 3,
          icon: 'fas fa-clipboard-check',
          title: 'En Uygun Sigorta Poliçesini Anında Seçin',
          desc:
            'Çok sayıda mevcut fiyat teklifi arasından gereksinimlerinize ve bütçenize en uygun olanı seçin.'
        },
        {
          id: 4,
          icon: 'fas fa-check-circle',
          title: 'Seçtiğiniz Poliçeyi Online Satın Alın',
          desc:
            'Kolay ödeme avantajı ile kredi kartına özel taksit imkânından yararlanın, poliçenizi hemen alın.'
        }
      ]
    }
  }
}
</script>
<style scoped></style>
