<template>
  <section class="special--offers mt-10">
    <!-- Title area starts -->
    <v-container class="mx-auto section--wrapper">
      <v-row
        no-gutters
        class="flex-column flex-sm-row justify-space-between align-center special--offers--wrapper"
      >
        <div class="d-none d-sm-flex"></div>
        <div>
          <h2 class="section-title">
            {{ specialOffersData.mainTitle }}
          </h2>
        </div>

        <div class="text-center text-sm-right mt-2 mt-sm-0">
          <!-- <router-link
            class="section--link "
            :to="specialOffersData.link.path || '/'"
          >
            {{ specialOffersData.link.text || 'Tüm Kampanyalar' }}
          </router-link> -->
        </div>
      </v-row>
    </v-container>
    <!-- Title area ends -->
    <!-- Slider starts -->
    <v-container>
      <app-carousel :options="options">
        <splide-slide v-for="(slide, i) in slides" :key="i">
          <v-img
            max-width="403"
            max-height="312"
            :src="slide.src"
            :alt="slide.alt"
          ></v-img>
        </splide-slide>
      </app-carousel>
    </v-container>
    <!-- Slider ends -->
  </section>
</template>

<script>
import appCarousel from "@/component/carousel/AppCarousel"
export default {
  props: {
    specialOffersData: {
      required: false,
      type: Object
    }
  },
  components: {
    appCarousel
  },
  data() {
    return {
      options: {
        type: "slide",
        rewind: true,
        height: "24rem",
        arrows: false,
        autoWidth: "403",
        autoplay: true,
        interval: 4000,
        gap: "1rem",
        perPage: 3,
        breakpoints: {
          570: {
            height: "30rem"
          }
        }
      },
      slides: [
        {
          src: require("@/assets/special-offers/offer1.jpg"),
          alt: "kampanya"
        },
        {
          src: require("@/assets/special-offers/offer2.jpg"),
          alt: "kampanya"
        },
        {
          src: require("@/assets/special-offers/offer4.png"),
          alt: "kampanya"
        },
        {
          src: require("@/assets/special-offers/offer3.jpg"),
          alt: "kampanya"
        }
      ]
    }
  }
}
</script>
