<template>
  <div>
    <!--<v-row>
      <v-col col="12">
        <v-row justify="center">
          <v-dialog v-model="dialog" persistent max-width="450">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on" disabled
                >Open Dialog</v-btn
              >
            </template>
            <v-card class="mt-15 pa-2">
              <v-carousel
                cycle
                height="450"
                hide-delimiter-background
                show-arrows
              >
                <v-carousel-item
                  v-for="(item, i) in items"
                  :key="i"
                  :src="item.imgsource"
                  contain
                ></v-carousel-item>
              </v-carousel>
              <v-card-actions class="mt-1">
                <v-spacer></v-spacer>
                <v-btn text color="success" @click="dialog = false"
                  >Siteye Devam Et</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-col>
    </v-row>-->
    <Navbar />
    <section class="home">
      <Brand />
      <app-special-offers :specialOffersData="specialOffersData" />
      <!-- <advertisement-movies />  REKLAMLAR EKLENENE KADAR PASİFE ALINDI-->
      <get-quote />
      <alo-kasko-support />
      <app-contracted-insurance-companies :buttons="companyButtons" />
      <app-alo-kasko-blog>
        <v-col cols="12">
          <v-btn
            @click="$router.push('/sikca-sorulan-sorular')"
            class="faq--btn mt-10"
            block
            color="success"
            height="70"
            ><span class="faq--text"><h2>Sıkça Sorulan Sorular</h2></span>
            <span> <i class="fas fa-arrow-right fa-2x  ml-3"></i> </span
          ></v-btn>
        </v-col>
      </app-alo-kasko-blog>
      <app-insurance-dictionary />
      <app-customer-comments />
      <contact-us-form />
    </section>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/component/navbar/Navbar"
import Brand from "@/views/homepage/Brand"
import appSpecialOffers from "@/component/special-offers/AppSpecialOffers"
// import AdvertisementMovies from '@/views/homepage/AdvertisementMovies'
import getQuote from "@/views/homepage/GetQuote"
import aloKaskoSupport from "@/views/homepage/AloKaskoSupport"
import appContractedInsuranceCompanies from "@/component/contracted-insurance-companies/AppContractedInsuranceCompanies"
import appAloKaskoBlog from "@/component/alo-kasko-blog-carousel/AppAloKaskoBlog"
import appInsuranceDictionary from "@/component/insurance-dictionary/AppInsuranceDictionary"
import appCustomerComments from "@/component/customer-comments/AppCustomerComments"
import contactUsForm from "@/views/homepage/ContactUsForm"
import Footer from "@/component/footer/Footer"
import buttonData from "@/data/Companies.js"
// import photo1 from "@/assets/homepage/dialog-img-1.png"
// import photo2 from "@/assets/homepage/dialog-img-2.jpeg"

export default {
  metaInfo: {
    title: "Sigorta Uzmanından Alınır | Alokasko.com",
    meta: [
      {
        name: "description",
        content:
          "Alokasko müşterilerine online hızlı ve güvenilir kasko, trafik ve diğer sigorta branşlarında hizmet vermektedir. 20'den fazla sigorta şirketini karşılaştırın."
      }
    ]
  },
  name: "Home",
  head: {
    link: [
      { rel: 'canonical', href: 'https://www.alokasko.com' }
    ]
  },
  components: {
    Navbar,
    Brand,
    appSpecialOffers,
    // AdvertisementMovies,
    getQuote,
    appContractedInsuranceCompanies,
    aloKaskoSupport,
    appAloKaskoBlog,
    appInsuranceDictionary,
    appCustomerComments,
    contactUsForm,
    Footer
  },
  data() {
    return {
      //  items: [
      //    {
      //      imgsource: photo1
      //    },
      //    {
      //      imgsource: photo2
      //    }
      //  ],
      specialOffersData: {
        mainTitle: "Avantajlı Kampanyalar",
        link: {
          text: "Tüm Kampanyalar",
          path: "/"
        }
      },
      // dialog: true,
      mainTitle: "Reklam Filmlerimiz",
      linkTitle: "Tüm Reklam Filmleri",
      content: {
        slogan: "Doğru Fiyat, Doğru Ürün",
        title: "SİGORTANI UZMANINDAN AL!",
        desc:
          "En iyi fiyatı bulmayı kolaylaştırıyoruz. Sigorta fiyatlarını hesaplamak için ürünü seçin, ister online ister uzman eşliğinde kolayca karşılaştırma yaparak, en iyi fiyatı tek bir yerden alın."
      }
    }
  },
  computed: {
    companyButtons() {
      return [...buttonData.COMPANIES]
    }
  }
}
</script>
<style lang="scss">
@import "@/css/app.scss";
</style>
