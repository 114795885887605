const COMPANIES = [
  {
    id: 1,
    name: 'Ak Sigorta',
    value: 'AkSigorta',
    image: require('@/assets/company-logos/ak-sigorta.jpg'),
    path: '/ak-sigorta',
    subCategories: [
      {
        name: 'Trafik Sigortası',
        value: 'AkSigortaCompulsoryTraffic'
      },
      {
        name: 'Kasko Sigortası',
        value: 'AkSigortaMotorInsurance'
      },
      {
        name: 'Özel Sağlık Sigortası',
        value: 'AkSigortaHealthInsurance'
      },
      {
        name: 'Tamamlayıcı Sağlık Sigortası',
        value: 'AkSigortaComplementaryHealth'
      },
      {
        name: 'Seyahat Sağlık Sigortası',
        value: 'AkSigortaTravelInsurance'
      },
      {
        name: 'Konut Sigortası',
        value: 'AkSigortaHomeInsurance'
      },
      {
        name: 'Ferdi Kaza Sigortası',
        value: 'AkSigortaPersonalAccident'
      },
      {
        name: 'Dask Sigortası',
        value: 'AkSigortaDaskInsurance'
      },
      {
        name: 'İşyeri Sigortası',
        value: 'AkSigortaCompanyInsurance'
      },
      {
        name: 'Nakliyat Sigortası',
        value: 'AkSigortaTransportation'
      }
    ]
  },
  {
    id: 2,
    name: 'Allianz Sigorta',
    value: 'AllianzSigorta',
    image: require('@/assets/company-logos/allianz-sigorta.jpg'),
    path: '/allianz-sigorta',
    subCategories: [
      {
        name: 'Kasko Sigortası',
        value: 'AllianzSigortaMotorInsurance'
      },
      {
        name: 'Trafik Sigortası',
        value: 'AllianzSigortaCompulsoryTraffic'
      },
      {
        name: 'Özel Sağlık Sigortası',
        value: 'AllianzSigortaHealth'
      },
      {
        name: 'Konut Sigortası',
        value: 'AllianzSigortaHomeInsurance'
      },
      {
        name: 'Dask Sigortası',
        value: 'AllianzSigortaDaskInsurance'
      },
      {
        name: 'İşyeri Sigortası',
        value: 'AllianzSigortaCompanyInsurance'
      },
      {
        name: 'Ferdi Kaza Sigortası',
        value: 'AllianzSigortaPersonalAccidentInsurance'
      },
      {
        name: 'Nakliyat Sigortası',
        value: 'AllianzSigortaTransportationInsurance'
      },
      {
        name: 'Tamamlayıcı Sağlık Sigortası',
        value: 'AllianzSigortaComplementaryInsurance'
      },
      {
        name: 'Seyahat Sağlık Sigortası',
        value: 'AllianzSigortaTravelInsurance'
      }
    ]
  },
  {
    id: 3,
    name: 'Ankara Sigorta',
    value: 'AnkaraSigorta',
    image: require('@/assets/company-logos/ankara-sigorta.jpg'),
    path: '/ankara-sigorta',
    subCategories: [
      {
        name: 'Trafik Sigortası',
        value: 'AnkaraSigortaCompulsoryTraffic'
      },
      {
        name: 'Kasko Sigortası',
        value: 'AnkaraSigortaMotorInsurance'
      },
      {
        name: 'Yabancı Sağlık Sigortası',
        value: 'AnkaraSigortaForeignHealtInsurance'
      },
      {
        name: 'Tamamlayıcı Sağlık Sigortası',
        value: 'AnkaraSigortaComplementaryHealth'
      },
      {
        name: 'Seyahat Sağlık Sigortası',
        value: 'AnkaraSigortaTravelHealtInsurance'
      },
      {
        name: 'Konut Sigortası',
        value: 'AnkaraSigortaHomeInsurance'
      },
      {
        name: 'Ferdi Kaza Sigortası',
        value: 'AnkaraSigortaPersonalAccident'
      },
      {
        name: 'Dask Sigortası',
        value: 'AnkaraSigortaDaskInsurance'
      },
      {
        name: 'İşyeri Sigortası',
        value: 'AnkaraSigortaCompanyInsurance'
      },
      {
        name: 'Nakliyat Sigortası',
        value: 'AnkaraSigortaTransportation'
      }
    ]
  },
  {
    id: 4,
    name: 'Atlas Sigorta',
    value: 'AtlasMutuel',
    image: require('@/assets/company-logos/atlas-mutuel.jpg'),
    path: '/atlas-mutuel-sigorta',
    subCategories: [
      {
        name: 'Kasko Sigortası',
        value: 'AtlasMutuelMotorInsurance'
      },
      {
        name: 'Konut Sigortası',
        value: 'AtlasMutuelHomeInsurance'
      },
      {
        name: 'Dask Sigortası',
        value: 'AtlasMutuelDask'
      },
      {
        name: 'İşyeri Sigortası',
        value: 'AtlasMutuelCompanyInsurance'
      },
      {
        name: 'Ferdi Kaza Sigortası',
        value: 'AtlasMutuelPersonalAccident'
      },
      {
        name: 'Nakliyat Sigortası',
        value: 'AtlasMutuelTransportation'
      },
      {
        name: 'Seyahat Sağlık Sigortası',
        value: 'AtlasMutuelTravelInsurance'
      }
    ]
  },
  {
    id: 5,
    name: 'Bereket Sigorta',
    value: 'BereketSigorta',
    image: require('@/assets/company-logos/bereket-sigorta.jpg'),
    path: '/bereket-sigorta',
    subCategories: [
      {
        name: 'Kasko Sigortası',
        value: 'BereketSigortaMotorInsurance'
      },
      {
        name: 'Trafik Sigortası',
        value: 'BereketSigortaCompulsoryTraffic'
      },
      {
        name: 'Konut Sigortası',
        value: 'BereketSigortaHomeInsurance'
      },
      {
        name: 'Dask Sigortası',
        value: 'BereketSigortaDaskInsurance'
      },
      {
        name: 'İşyeri Sigortası',
        value: 'BereketSigortaCompanyInsurance'
      },
      {
        name: 'Ferdi Kaza Sigortası',
        value: 'BereketSigortaPersonalAccident'
      },
      {
        name: 'Nakliyat Sigortası',
        value: 'BereketSigortaTransportation'
      },
      {
        name: 'Seyahat Sağlık Sigortası',
        value: 'BereketSigortaTravelInsurance'
      }
    ]
  },
  {
    id: 6,
    name: 'Groupama Sigorta',
    value: 'GroupamaSigorta',
    image: require('@/assets/company-logos/groupama-sigorta.jpg'),
    path: '/groupama-sigorta',
    subCategories: [
      {
        name: 'Trafik Sigortası',
        value: 'GroupamaSigortaCompulsoryTraffic'
      },
      {
        name: 'Kasko Sigortası',
        value: 'GroupamaSigortaMotorInsurance'
      },
      {
        name: 'Dask Sigortası',
        value: 'GroupamaSigortaDaskInsurance'
      },
      {
        name: 'Konut Sigortası',
        value: 'GroupamaSigortaHomeInsurance'
      },
      {
        name: 'İşyeri Sigortası',
        value: 'GroupamaSigortaCompanyInsurance'
      },
      {
        name: 'Ferdi Kaza Sigortası',
        value: 'GroupamaSigortaPersonalAccident'
      },
      {
        name: 'Nakliyat Sigortası',
        value: 'GroupamaSigortaTransportation'
      },
      {
        name: 'Seyahat Sağlık Sigortası',
        value: 'GroupamaSigortaTravelInsurance'
      },
      {
        name: 'Yabancı Sağlık Sigortası',
        value: 'GroupamaSigortaForeignInsurance'
      }
    ]
  },
  {
    id: 7,
    name: 'Quick Sigorta',
    value: 'QuickSigorta',
    image: require('@/assets/company-logos/quick-sigorta.jpg'),
    path: '/quick-sigorta',
    subCategories: [
      {
        name: 'Trafik Sigortası',
        value: 'QuickSigortaCompulsoryTraffic'
      },
      {
        name: 'Kasko Sigortası',
        value: 'QuickSigortaMotorInsurance'
      },
      {
        name: 'Dask Sigortası',
        value: 'QuickSigortaDaskInsurance'
      },
      {
        name: 'Konut Sigortası',
        value: 'QuickSigortaHomeInsurance'
      },
      {
        name: 'İşyeri Sigortası',
        value: 'QuickSigortaCompanyInsurance'
      },
      {
        name: 'Ferdi Kaza Sigortası',
        value: 'QuickSigortaPersonalInsurance'
      },
      {
        name: 'Nakliyat Sigortası',
        value: 'QuickSigortaTransportationInsurance'
      },
      {
        name: 'Kefalet Sigortası',
        value: 'QuickSigortaSuretyInsurance'
      },
      {
        name: 'Alacak Sigortası',
        value: 'QuickSigortaCreditInsurance'
      },
      {
        name: 'Seyahat Sağlık Sigortası',
        value: 'QuickSigortaTravelInsurance'
      }
    ]
  },
  {
    id: 8,
    name: 'Ray Sigorta',
    value: 'RaySigorta',
    image: require('@/assets/company-logos/ray-sigorta.jpg'),
    path: '/ray-sigorta',
    subCategories: [
      {
        name: 'Trafik Sigortası',
        value: 'RaySigortaCompulsoryTraffic'
      },
      {
        name: 'Kasko Sigortası',
        value: 'RaySigortaMotorInsurance'
      },
      {
        name: 'Dask Sigortası',
        value: 'RaySigortaDaskInsurance'
      },
      {
        name: 'Konut Sigortası',
        value: 'RaySigortaHomeInsurance'
      },
      {
        name: 'İşyeri Sigortası',
        value: 'RaySigortaCompanyInsurance'
      },
      {
        name: 'Ferdi Kaza Sigortası',
        value: 'RaySigortaPersonalAccident'
      },
      {
        name: 'Nakliyat Sigortası',
        value: 'RaySigortaTransportation'
      },
      {
        name: 'Tamamlayıcı Sağlık Sigortası',
        value: 'RaySigortaComplementaryHealth'
      },
      {
        name: 'Seyahat Sağlık Sigortası',
        value: 'RaySigortaTravelInsurance'
      }
    ]
  },
  {
    id: 9,
    name: 'Sompo Sigorta',
    value: 'SompoSigorta',
    image: require('@/assets/company-logos/sompo-sigorta.png'),
    path: '/sompo-sigorta',
    subCategories: [
      {
        name: 'Trafik Sigortası',
        value: 'SompoSigortaCompulsoryTraffic'
      },
      {
        name: 'Kasko Sigortası',
        value: 'SompoSigortaMotorInsurance'
      },
      {
        name: 'Doğum Sigortası',
        value: 'SompoSigortaBirthInsurance'
      },
      {
        name: 'Yabancı Sağlık Sigortası',
        value: 'SompoSigortaForeignHealtInsurance'
      },
      {
        name: 'Tamamlayıcı Sağlık Sigortası',
        value: 'SompoSigortaComplementaryHealth'
      },
      {
        name: 'Seyahat Sağlık Sigortası',
        value: 'SompoSigortaTravelHealthInsurance'
      },
      {
        name: 'Konut Sigortası',
        value: 'SompoSigortaHomeInsurance'
      },
      {
        name: 'Ferdi Kaza Sigortası',
        value: 'SompoSigortaPersonalAccident'
      },
      {
        name: 'Dask Sigortası',
        value: 'SompoSigortaDaskInsurance'
      },
      {
        name: 'İşyeri Sigortası',
        value: 'SompoSigortaCompanyInsurance'
      },
      {
        name: 'Nakliyat Sigortası',
        value: 'SompoSigortaTransportation'
      }
    ]
  },
  {
    id: 10,
    name: 'Türk Nippon Sigorta',
    value: 'TurkNipponSigorta',
    image: require('@/assets/company-logos/turk-nippon.png'),
    path: '/turk-nippon-sigorta',
    subCategories: [
      {
        name: 'Trafik Sigortası',
        value: 'TurkNipponSigortaCompulsoryTraffic'
      },
      {
        name: 'Kasko Sigortası',
        value: 'TurkNipponSigortaMotorInsurance'
      },
      {
        name: 'Dask Sigortası',
        value: 'TurkNipponSigortaDaskInsurance'
      },
      {
        name: 'Konut Sigortası',
        value: 'TurkNipponSigortaHomeInsurance'
      },
      {
        name: 'İşyeri Sigortası',
        value: 'TurkNipponSigortaCompanyInsurance'
      },
      {
        name: 'Ferdi Kaza Sigortası',
        value: 'TurkNipponSigortaPersonalAccident'
      },
      {
        name: 'Nakliyat Sigortası',
        value: 'TurkNipponSigortaTransportation'
      },
      {
        name: 'İkinci El Araç Garanti Sigortası',
        value: 'TurkNipponSigortaSecondhandGuaranteeInsurance'
      },
      {
        name: 'Tamamlayıcı Sağlık Sigortası',
        value: 'TurkNipponSigortaComplementaryHealth'
      },
      {
        name: 'Doğum Sigortası',
        value: 'TurkNipponSigortaBirthInsurance'
      },
      {
        name: 'Seyahat Sağlık Sigortası',
        value: 'TurkNipponSigortaTravelInsurance'
      }
    ]
  },
  {
    id: 11,
    name: 'Türkiye Sigorta',
    value: 'TurkiyeSigorta',
    image: require('@/assets/company-logos/turkiye-sigorta.jpg'),
    path: '/turkiye-sigorta',
    subCategories: [
      { name: 'Trafik Sigortası', value: 'TurkiyeSigortaCompulsoryTraffic' },
      { name: 'Kasko Sigortası', value: 'TurkiyeSigortaMotorInsurance' },
      { name: 'Dask Sigortası', value: 'TurkiyeSigortaDaskInsurance' },
      { name: 'Konut Sigortası', value: 'TurkiyeSigortaHomeInsurance' },
      { name: 'İşyeri Sigortası', value: 'TurkiyeSigortaCompanyInsurance' },
      { name: 'Ferdi Kaza Sigortası', value: 'TurkiyeSigortaPersonalAccident' },
      { name: 'Nakliyat Sigortası', value: 'TurkiyeSigortaTransportation' },
      { name: 'Özel Sağlık Sigortası', value: 'TurkiyeSigortaHealthInsurance' },
      {
        name: 'Tamamlayıcı Sağlık Sigortası',
        value: 'TurkiyeSigortaComplementaryHealth'
      },
      {
        name: 'Seyahat Sağlık Sigortası',
        value: 'TurkiyeSigortaTravelInsurance'
      }
    ]
  },
  {
    id: 12,
    name: 'Unico Sigorta',
    value: 'UnicoSigorta',
    image: require('@/assets/company-logos/unico.jpg'),
    path: '/unico-sigorta',
    subCategories: [
      {
        name: 'Trafik Sigortası',
        value: 'UnicoSigortaCompulsoryTraffic'
      },
      {
        name: 'Kasko Sigortası',
        value: 'UnicoSigortaMotorInsurance'
      },
      {
        name: 'Seyahat Sağlık Sigortası',
        value: 'UnicoSigortaTravelInsurance'
      },
      {
        name: 'Konut Sigortası',
        value: 'UnicoSigortaHomeInsurance'
      },
      {
        name: 'Ferdi Kaza Sigortası',
        value: 'UnicoSigortaPersonalAccident'
      },
      {
        name: 'Dask Sigortası',
        value: 'UnicoSigortaDaskInsurance'
      },
      {
        name: 'İşyeri Sigortası',
        value: 'UnicoSigortaCompanyInsurance'
      },
      {
        name: 'Nakliyat Sigortası',
        value: 'UnicoSigortaTransportation'
      }
    ]
  },
  {
    id: 13,
    name: 'Zurich Sigorta',
    value: 'ZurichSigorta',
    image: require('@/assets/company-logos/zurich-sigorta.png'),
    path: '/zurich-sigorta',
    subCategories: [
      {
        name: 'Trafik Sigortası',
        value: 'ZurichSigortaCompulsoryTraffic'
      },
      {
        name: 'Kasko Sigortası',
        value: 'ZurichSigortaMotorInsurance'
      },
      {
        name: 'Dask Sigortası',
        value: 'ZurichSigortaDaskInsurance'
      },
      {
        name: 'Konut Sigortası',
        value: 'ZurichSigortaHomeInsurance'
      },
      {
        name: 'İşyeri Sigortası',
        value: 'ZurichSigortaCompanyInsurance'
      },
      {
        name: 'Ferdi Kaza Sigortası',
        value: 'ZurichSigortaPersonalAccident'
      },
      {
        name: 'Nakliyat Sigortası',
        value: 'ZurichSigortaTransportation'
      },
      {
        name: 'Seyahat Sağlık Sigortası',
        value: 'ZurichSigortaTravelInsurance'
      }
    ]
  },
  {
    id: 14,
    name: 'Mapfre Sigorta',
    value: 'MapfreSigorta',
    image: require('@/assets/company-logos/mapfre-sigorta.png'),
    path: '/mapfre-sigorta',
    subCategories: [
      {
        name: 'Trafik Sigortası',
        value: 'MapfreSigortaCompulsoryTraffic'
      },
      {
        name: 'Kasko Sigortası',
        value: 'MapfreSigortaMotorInsurance'
      },
      {
        name: 'Dask Sigortası',
        value: 'MapfreSigortaDaskInsurance'
      },
      {
        name: 'Konut Sigortası',
        value: 'MapfreSigortaHomeInsurance'
      },
      {
        name: 'İşyeri Sigortası',
        value: 'MapfreSigortaCompanyInsurance'
      },
      {
        name: 'Ferdi Kaza Sigortası',
        value: 'MapfreSigortaPersonalAccident'
      },
      {
        name: 'Nakliyat Sigortası',
        value: 'MapfreSigortaTransportation'
      },
      {
        name: 'Özel Sağlık Sigortası',
        value: 'MapfreSigortHealthInsurance'
      },

      {
        name: 'Tamamlayıcı Sağlık Sigortası',
        value: 'MapfreSigortaComplementaryHealth'
      },
      {
        name: 'Seyahat Sağlık Sigortası',
        value: 'MapfreSigortaTravelInsurance'
      }
    ]
  },
  {
    id: 15,
    name: 'Generali Sigorta',
    value: 'GeneraliSigorta',
    image: require('@/assets/company-logos/generali-sigorta.png'),
    path: '/generali-sigorta',
    subCategories: [
      {
        name: 'Trafik Sigortası',
        value: 'GeneraliSigortaCompulsoryTraffic'
      },
      {
        name: 'Kasko Sigortası',
        value: 'GeneraliSigortaMotorInsurance'
      },
      {
        name: 'Dask Sigortası',
        value: 'GeneraliSigortaDaskInsurance'
      },
      {
        name: 'Konut Sigortası',
        value: 'GeneraliSigortaHomeInsurance'
      },
      {
        name: 'İşyeri Sigortası',
        value: 'GeneraliSigortaCompanyInsurance'
      },
      {
        name: 'Ferdi Kaza Sigortası',
        value: 'GeneraliSigortaPersonalAccident'
      },
      {
        name: 'Nakliyat Sigortası',
        value: 'GeneraliSigortaTransportation'
      },
      {
        name: 'Seyahat Sağlık Sigortası',
        value: 'GeneraliSigortaTravelInsurance'
      }
    ]
  },
  {
    id: 16,
    name: 'HDI Sigorta',
    value: 'HdiSigorta',
    image: require('@/assets/company-logos/hdi-sigorta.jpg'),
    path: '/hdi-sigorta',
    subCategories: [
      { name: 'Trafik Sigortası', value: 'HdiSigortaCompulsoryTraffic' },
      { name: 'Kasko Sigortası', value: 'HdiSigortaMotorInsurance' },
      { name: 'Dask Sigortası', value: 'HdiSigortaDaskInsurance' },
      { name: 'Konut Sigortası', value: 'HdiSigortaHomeInsurance' },
      { name: 'İşyeri Sigortası', value: 'HdiSigortaCompanyInsurance' },
      { name: 'Ferdi Kaza Sigortası', value: 'HdiSigortaPersonalAccident' },
      { name: 'Nakliyat Sigortası', value: 'HdiSigortaTransportation' },
      { name: 'Cep Telefonu Sigortası', value: 'HdiSigortaMobileInsurance' },
      {
        name: 'Tamamlayıcı Sağlık Sigortası',
        value: 'HdiSigortaComplementaryHealth'
      },
      {
        name: 'Seyahat Sağlık Sigortası',
        value: 'HdiSigortaTravelInsurance'
      }
    ]
  },
  {
    id: 17,
    name: 'Fiba Emeklilik',
    value: 'PersonalAccidentInsurance',
    image: require('@/assets/company-logos/fiba-emeklilik.jpg'),
    path: '/fiba-emeklilik',
    subCategories: [
      {
        name: 'Bireysel Emeklilik',
        value: 'FibaEmeklilikRetirement'
      }
    ]
  },
  {
    id: 18,
    name: 'Axa Sigorta',
    value: 'AxaSigorta',
    image: require('@/assets/company-logos/axa-sigorta.png'),
    path: '/axa-sigorta',
    subCategories: [
      {
        name: 'Kasko Sigortası',
        value: 'AxaSigortaMotorInsurance'
      },
      {
        name: 'Trafik Sigortası',
        value: 'AxaSigortaCompulsoryTraffic'
      },
      {
        name: 'Özel Sağlık Sigortası',
        value: 'AxaSigortHealthInsurance'
      },
      {
        name: 'Konut Sigortası',
        value: 'AxaSigortaHomeInsurance'
      },
      {
        name: 'Dask Sigortası',
        value: 'AxaSigortaDaskInsurance'
      },
      {
        name: 'İşyeri Sigortası',
        value: 'AxaSigortaCompanyInsurance'
      },
      {
        name: 'Ferdi Kaza Sigortası',
        value: 'AxaSigortaPersonalAccident'
      },
      {
        name: 'Nakliyat Sigortası',
        value: 'AxaSigortaTransportation'
      },

      {
        name: 'Seyahat Sağlık Sigortası',
        value: 'AxaSigortaTravelInsurance'
      }
    ]
  },
  {
    id: 19,
    name: 'Demir Hayat',
    value: 'DemirHayat',
    image: require('@/assets/company-logos/demir-sigorta.png'),
    path: '/demir-sigorta',
    subCategories: [
      {
        name: 'Özel Sağlık Sigortası',
        value: 'DemirSigortaHealthInsurance'
      },

      {
        name: 'Tamamlayıcı Sağlık Sigortası',
        value: 'DemirSigortaComplementaryInsurance'
      },
      {
        name: 'Doğum Sigortası',
        value: 'DemirSigortaBirthInsurance'
      },
      {
        name: 'Yabancı Sağlık Sigortası',
        value: 'DemirSigortaForeignInsurance'
      }
    ]
  }
]

export default {
  COMPANIES
}
