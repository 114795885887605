<template>
  <section class="home-header">
    <v-row no-gutters class="hero align-center">
      <v-col cols="12" md="6" lg="4" class="pl-md-10 text-center text-md-left">
        <div class="hero--slogan">Doğru Fiyat, Doğru Ürün!!</div>
        <h1 class="hero--title mt-5">SİGORTANI UZMANINDAN AL!</h1>
        <p class="hero--desc mt-5">
          En iyi fiyatı bulmayı kolaylaştırıyoruz. Sigorta fiyatlarını
          hesaplamak için ürünü seçin, ister online ister uzman eşliğinde
          kolayca karşılaştırma yaparak, en iyi fiyatı tek bir yerden alın
        </p>
      </v-col>
      <v-col cols="12" md="6" lg="6" class="order-md-first">
        <img
          width="100%"
          height="100%"
          src="@/assets/homepage/home-brand.jpg"
          alt="Alokasko brand"
          loading="lazy"
        />
      </v-col>
    </v-row>
    <div class="hero--buttons">
      <router-link
        :to="{ name: btn.path }"
        target="_blank"
        class="hero--btn px-5 text-decoration-none"
        v-for="btn in buttons"
        :key="btn.id"
      >
        <div><i :class="btn.class"></i></div>
        <div class="hero--btn--title ml-md-2">{{ btn.title }}</div>
        <div class="hero--btn--arrow">
          <i class="fas fa-arrow-right fa-lg"></i>
        </div>
      </router-link>
    </div>
  </section>
</template>

<script>
import QuotationType from "@/data/QuotationType"

export default {
  data() {
    return {
      buttons: [
        {
          id: 1,
          title: "KASKO SİGORTASI TEKLİFİ AL",
          insuranceType: QuotationType.INSURANCE,
          class: "hero--btn--icon fas fa-car-crash fa-2x mr-2",
          path: "MotorInsurance"
        },
        {
          id: 2,
          title: "TRAFİK SİGORTASI  TEKLİFİ AL",
          insuranceType: QuotationType.TRAFFIC,
          class: "hero--btn--icon fas fa-car fa-2x mr-2",
          path: "CompulsoryTrafficInsurance"
        },
        {
          id: 3,
          title: "SAĞLIK SİGORTASI  TEKLİFİ AL",
          insuranceType: QuotationType.HEALTH,
          class: "hero--btn--icon fas fa-heartbeat fa-2x mr-2",
          path: "HealthInsurance"
        },
        {
          id: 4,
          title: "KONUT SİGORTASI  TEKLİFİ AL",
          insuranceType: QuotationType.BUILDING,
          class: "hero--btn--icon fas fa-house-damage fa-2x mr-2",
          path: "HousingInsurance"
        },
        {
          id: 5,
          title: "DASK SİGORTA  TEKLİFİ AL",
          insuranceType: QuotationType.DASK,
          class: "hero--btn--icon fas fa-laptop-house fa-2x mr-2",
          path: "DaskInsurance"
        },
        {
          id: 6,
          title: "CEP TELEFONU SİGORTA TEKLİFİ AL",
          insuranceType: QuotationType.MOBILEPHONE,
          class: "hero--btn--icon fas fa-mobile fa-2x mr-2",
          path: "MobilePhoneInsurance"
        },
        {
          id: 7,
          title: "2. EL GARANTİ SİGORTASI TEKLİFİ AL",
          insuranceType: QuotationType.SECONDHAND,
          class: "hero--btn--icon fas fa-key fa-2x mr-2",
          path: "SecondhandGuaranteeInsurance"
        },
        {
          id: 8,
          title: "BİREYSEL EMEKLİLİK SİGORTASI TEKLİFİ AL",
          insuranceType: QuotationType.INDIVIDUALRETIREMENT,
          class: "hero--btn--icon fas fa-wallet fa-2x mr-2",
          path: "IndividualAnnuityInsurance"
        }
      ]
    }
  }
  // methods: {
  //   updateSelectedInsuranceType (insuranceType) {
  //     // initial da form render etmediği için prop ile geçildi. Dialog form render edildikten sonra update metodu çağırılarak güncellendi.
  //     // TODO two way binding ile prop kullanılarak veya farklı bir yöntem ile uygulanabilir.
  //     this.selectedInsuranceType = insuranceType
  //     if (this.$refs.customerInfoFormRef) {
  //       this.$refs.customerInfoFormRef.updateInsuranceType(insuranceType)
  //     }
  //   }
  // }
}
</script>
