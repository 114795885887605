<template>
  <section class="ak--support">
    <div class="ak--support--wrapper">
      <h2>{{ mainTitle }}</h2>
      <!-- Content Starts -->
      <div class="ak--support--content">
        <v-col
          cols="12"
          md="5"
          lg="5"
          v-for="item in content"
          class="text-center text-lg-left"
          :key="item.title"
        >
          <h3>{{ item.title }}</h3>
          <p>
            {{ item.desc }}
          </p>
        </v-col>
      </div>
      <!-- Content ends -->
      <!-- Features start -->
      <v-row no-gutters class="ak--support--sheets justify-center">
        <v-col
          cols="10"
          lg="2"
          sm="4"
          v-for="feature in features"
          :key="feature.title"
        >
          <i :class="feature.icon"></i>
          <h4 v-html="feature.title" class="text-center"></h4>
        </v-col>
      </v-row>
      <!-- Features end -->
    </div>
  </section>
</template>

<script>
export default {
  data () {
    return {
      mainTitle: 'Alokasko Tüm İşlerinizde Yanınızda!',
      content: [
        {
          title: 'Fiyat Araştırması Yaparken',
          desc:
            ' Sigorta teminatlarını karşılaştırarak, daha ucuz fiyat bulmak hızlı ve kolaydır. Sadece hakkınızda birkaç ayrıntıya ihtiyacımız var.'
        },
        {
          title: 'Hasar Anında',
          desc:
            'Sigorta kapsamında oluşan zarar ve hasarlarda her daim sizinleyiz. Hasar anında uzmanlarımız bir telefon uzağınızda!'
        },
        {
          title: 'Karar Verme Sürecinde',
          desc:
            'Tasarruf etmeye başlamak çok daha kolay. En uygun sigorta fiyatını online karşılaştırırken yanınızdayız.'
        },
        {
          title: 'Poliçe Yenileme Sırasında',
          desc:
            'Sigortanızı takip etmeyi bırakabilirsiniz, sizin yerinize tüm süreci biz takip ediyoruz. Poliçe yenileme sırasında indirimli fiyatlar bizden size!'
        }
      ],
      features: [
        {
          icon: 'fas fa-thumbs-up fa-3x',
          title: 'Avantajlı <br/> Fiyat Garantisi'
        },
        {
          icon: 'fas fa-copy fa-3x',
          title: ' Tek Ekranda <br /> Karşılaştırmalı Fiyatlar'
        },
        {
          icon: 'fas fa-mobile fa-3x',
          title: ' İnternete Özel<br /> İndirimler'
        },
        {
          icon: 'fas fa-headset fa-3x',
          title: '7/24 Hasar<br /> Destek Hattı'
        }
      ]
    }
  }
}
</script>
