const CompulsoryTrafficInsurance = "Trafik Sigortası"
const MotorInsurance = "Kasko Sigortası"
const PrivateHealthInsurance = "Özel Sağlık Sigortası"
const DaskInsurance = "Dask Sigorta"
const HousingInsurance = "Konut Sigortası"
const ForeignHealthInsurance = "Yabancı Sağlık Sigortası"
const ComplementHealthInsurance = "Tamamlayıcı Sağlık Sigortası"
const BirthInsurance = "Doğum Sağlık Sigortası"
const TravelHealthInsurance = "Seyahat Sağlık Sigortası"
const CompanyInsurance = "İşYeri Sigortası"
const PersonalAccidentInsurance = "Ferdi Kaza Sigortası"
const ShippingInsurance = "Nakliyat Sigortası"
const MobilePhoneInsurance = "Cep Telefonu Sigortası"
const SecondhandGuaranteeInsurance = "2. El Garanti Sigortası"
const SuretyInsurance = "Kefalet Sigortası"
const CreditInsurance = "Alacak Sigortası"
const IndividualAnnuityInsurance = "Bireysel  Emeklilik Sigortası"

export default {
  CompulsoryTrafficInsurance: CompulsoryTrafficInsurance,
  MotorInsurance: MotorInsurance,
  PrivateHealthInsurance: PrivateHealthInsurance,
  DaskInsurance: DaskInsurance,
  HousingInsurance: HousingInsurance,
  MobilePhoneInsurance: MobilePhoneInsurance,
  SecondhandGuaranteeInsurance: SecondhandGuaranteeInsurance,
  ForeignHealthInsurance: ForeignHealthInsurance,
  ComplementHealthInsurance: ComplementHealthInsurance,
  TravelHealthInsurance: TravelHealthInsurance,
  BirthInsurance: BirthInsurance,
  PersonalAccidentInsurance: PersonalAccidentInsurance,
  ShippingInsurance: ShippingInsurance,
  SuretyInsurance: SuretyInsurance,
  CreditInsurance: CreditInsurance,
  CompanyInsurance: CompanyInsurance,
  IndividualAnnuityInsurance: IndividualAnnuityInsurance,
  ALL: [
    MotorInsurance,
    CompulsoryTrafficInsurance,
    PrivateHealthInsurance,
    HousingInsurance,
    DaskInsurance,
    MobilePhoneInsurance,
    SecondhandGuaranteeInsurance,
    IndividualAnnuityInsurance,
    ForeignHealthInsurance,
    ComplementHealthInsurance,
    TravelHealthInsurance,
    BirthInsurance,
    PersonalAccidentInsurance,
    ShippingInsurance,
    SuretyInsurance,
    CreditInsurance,
    CompanyInsurance
  ]
}
