<template>
  <div></div>
  <!-- <section class="dictionary-slider">
    <v-container class="mx-auto">
      <div class="d-flex flex-column mt-5">
        <h2 class="section-title">{{ mainTitle }}</h2>
        <div
          class="d-flex flex-column flex-md-row align-center justify-center justify-md-space-between dictionary--container"
        >
          <div class="d-none d-md-block"></div>
          <p class="all-quote">
            {{ titleDesc }}
          </p>
          <router-link :to="linkPath" class="dictionary--videos">{{
            linkName
          }}</router-link>
        </div>
      </div>
      <app-carousel :options="options" class="mt-10">
        <splide-slide v-for="(slide, i) in slides" :key="i">
          <v-img
            min-height="100"
            aspect-ratio="1/4"
            :alt="slide.title"
            :src="slide.src"
          ></v-img>
          <h3 class="dictionary--title">{{ slide.title }}</h3>
        </splide-slide>
      </app-carousel>
    </v-container>
  </section> -->
</template>

<script>
// import appCarousel from '@/component/carousel/AppCarousel'
export default {
  components: {
    // appCarousel
  },
  props: {
    mainTitle: {
      type: String,
      required: false,
      default: 'Sigorta Sözlük'
    },
    titleDesc: {
      type: String,
      required: false,
      default: 'En çok merak edilenler terimleri yanıtlıyoruz.'
    },
    linkName: {
      type: String,
      required: false,
      default: 'Tüm Videoları Gör'
    },
    linkPath: {
      type: String,
      required: false,
      default: '/'
    }
  },
  data () {
    return {
      options: {
        type: 'slide',
        rewind: true,
        height: '25rem',
        lazyload: 'nearby',
        arrows: false,
        autoWidth: true,
        autoplay: true,
        interval: 4000,
        gap: '1rem',
        breakpoints: {
          550: {
            height: '30rem'
          }
        }
      },
      slides: [
        {
          src: require('@/assets/temp/dictionary/dictionary-1.svg'),
          title: 'Emtia Sigortası Nedir?'
        },
        {
          src: require('@/assets/temp/dictionary/dictionary-2.svg'),
          title: 'Konservasyon Nedir?'
        },
        {
          src: require('@/assets/temp/dictionary/dictionary-3.svg'),
          title: 'Cam Kırılması Sigortası Nedir?'
        },
        {
          src: require('@/assets/temp/dictionary/dictionary-4.svg'),
          title: 'Özel Sağlık Sigortası Nedir?'
        },
        {
          src: require('@/assets/temp/dictionary/dictionary-1.svg'),
          title: 'Emtia Sigortası Nedir?'
        },
        {
          src: require('@/assets/temp/dictionary/dictionary-2.svg'),
          title: 'Konservasyon Nedir?'
        },
        {
          src: require('@/assets/temp/dictionary/dictionary-3.svg'),
          title: 'Cam Kırılması Sigortası Nedir?'
        },
        {
          src: require('@/assets/temp/dictionary/dictionary-4.svg'),
          title: 'Özel Sağlık Sigortası Nedir?'
        }
      ]
    }
  }
}
</script>

<style></style>
