<template>
  <section class="contactUs d-flex  mt-10 py-10">
    <v-container>
      <v-row class="justify-center align-center">
        <v-col
          cols="10"
          lg="5"
          class="contactUs--content text-center text-lg-left"
        >
          <h3>
            Bazen Konuşmak Daha İyidir. Yardıma Mı İhtiyacınız Var? Biz Sizi
            Hemen Arayalım.
          </h3>
          <p>
            İster konuşmayı tercih edin ister daha fazla bilgiye ihtiyacınız
            olsun, uzman danışmanlarımız tasarruf etmenize yardımcı olmaya her
            zaman hazırdır
          </p>
        </v-col>
        <v-col cols="12" sm="8" md="10" lg="6" xl="7" class="mt-10 mt-md-0">
          <form class="contactUs--form ">
            <div>
              <input
                type="text"
                v-model="form.fullname"
                placeholder="Adınız Soyadınız"
              />
              <div>
                <span class="vertical-line"></span>
                <input
                  type="number"
                  v-model="form.mobileNumber"
                  v-mask="'0##########'"
                  placeholder="Telefon Numaranız"
                  :class="'ym-record-keys'"
                />
              </div>
            </div>
            <!-- Hide on mobile button -->
            <v-btn
              id="get_information"
              :disabled="loading || !formValidate"
              class="contactUs--form--submit d-none d-md-block"
              @click="sendForm"
              >Gönder</v-btn
            >
            <!-- Hide on mobile button -->
          </form>
        </v-col>
      </v-row>
      <v-container class="text-center">
        <!-- Only shown in mobile button -->
        <v-btn x-large rounded color="success" class="mobile-btn mt-5 d-md-none"
          >Gönder</v-btn
        >
        <!-- Only shown in mobile button -->
      </v-container>
    </v-container>
    <v-dialog v-model="successDialog" max-width="580">
      <v-card color="success pa-5">
        <v-card-title class="text-h6 white--text">
          Talebiniz Alınmıştır. Uzmanlarımız en kısa sürede size dönüş
          yapacaktır.
        </v-card-title>
      </v-card>
    </v-dialog>
    <v-dialog v-model="errorDialog" max-width="580">
      <v-card color="error pa-5">
        <v-card-title class="text-h6 white--text">
          Lütfen Tüm Alanları Doldurunuz.
        </v-card-title>
      </v-card>
    </v-dialog>
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="#224099" dark class="py-5">
        <v-card-text class="text-center">
          Bilgileriniz Gönderiliyor
          <v-progress-linear
            indeterminate
            color="white"
            class="mt-5"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import RestApiConstants from '@/data/RestApiConstants'
import axios from 'axios'
export default {
  data () {
    return {
      API_BASE_URL: null,
      successDialog: false,
      loading: false,
      errorDialog: false,
      form: {
        fullname: null,
        mobileNumber: ''
      }
    }
  },
  mounted () {
    axios
      .get('config.json')
      .then(resp => (this.API_BASE_URL = resp.data.BASE_URL))
  },
  methods: {
    sendForm () {
      const data = {
        aciklama: null,
        dateCreated: new Date().toISOString(),
        dateUpdated: new Date().toISOString(),
        mail: null,
        name: this.form.fullname,
        phoneNumber: this.form.mobileNumber,
        surname: null
      }
      if (this.isValidate()) {
        this.loading = true
        axios
          .post(this.API_BASE_URL + RestApiConstants.INFO_REQUEST_URL, data)
          .then(() => {
            this.successDialog = true
            this.resetForm()
          })
          .catch(() => alert('İsteğiniz Gerçekleştirilememiştir.'))
          .finally(() => (this.loading = false))
      } else this.errorDialog = true
    },
    isValidate () {
      const isfullNameExist = this.form.fullname
      if (this.form.mobileNumber.length === 11 && isfullNameExist) {
        return true
      } else return false
    },
    resetForm () {
      this.form = {
        fullname: null,
        mobileNumber: ''
      }
    }
  },
  computed: {
    formValidate () {
      const isfullNameExist = this.form.fullname
      if (this.form.mobileNumber.length === 11 && isfullNameExist) {
        return true
      } else return false
    }
  }
}
</script>

<style></style>
